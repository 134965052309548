import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import PageHero from '../components/PageHero'
import ContactScreen from '../components/Screens/ContactScreen'

const ContactPage = () => {
  const { t } = useTranslation()
  return (
    <Layout title={t('pages.contact.hero.title')}>
      <PageHero title={t('pages.contact.hero.title')} />
      <ContactScreen />
    </Layout>
  )
}

export default ContactPage
