import React from 'react'

const Maps = () => (
  <figure className="google-maps">
    <iframe
      title="gym-location"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11072.609540260699!2d18.2264056!3d46.0680026!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b727a9ef11d37ab!2sZr%20Team%20BJJ%20P%C3%A9cs!5e0!3m2!1shu!2shu!4v1657276375411!5m2!1shu!2shu"
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </figure>
)

export default Maps
