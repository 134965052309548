import React from 'react'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Maps from '../Maps'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactScreen = () => {
  const { t } = useTranslation()
  return (
    <div className="ContactScreen">
      <section id="contact-page">
        <Container>
          <Row>
            <Col md="6" className="contact-info-wrapper">
              <h3>{t('pages.contact.message.title')}</h3>
              <hr />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/zteambjjpecs"
                className="btn-messenger"
              >
                <FontAwesomeIcon
                  icon={['fab', 'facebook-messenger']}
                  className="icon"
                  size="sm"
                />
                {t('pages.contact.message.messenger')}
              </a>
            </Col>
            <Col md="6" className="contact-info-wrapper">
              <h3>{t('pages.contact.title')}</h3>
              <hr />
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    className="icon"
                    size="sm"
                  />
                  {t('address')}
                </li>
                <li>
                  <FontAwesomeIcon icon="phone" className="icon" size="sm" />
                  +36 (70) 679-0926
                </li>
                <li>
                  <FontAwesomeIcon icon="envelope" className="icon" size="sm" />
                  zrteampecs@gmail.com
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={['fab', 'facebook']}
                    className="icon"
                    size="sm"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://facebook.com/zteambjjpecs"
                  >
                    facebook.com/zrteambjjpecs
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={['fab', 'instagram']}
                    className="icon"
                    size="sm"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/zrteampecs/"
                  >
                    instagram.com/zrteampecs
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center contact-location">
            <Col md="10">
              <Maps />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ContactScreen
